import React from 'react';
import C18Anchor00 from './C18Anchor00'
import {loadAlarmsInfo,getZoneInfo,getZoneIndexFromSiteIndex,getAlarmInfo,getZoneAlarmInfo,
  loadSiteData,privs,loadMBInfo} from './C18utils'
import {alarmToSensor,getTZIndicator,makeSiteSensorNames,getTzo,getDst} from '../utils/utils';
import {cl,globs,constant,dateToDisplayDate} from '../../components/utils/utils';

class C18SidebarAlarms00 extends React.Component{
  constructor(props) {
    super(props);
//     cl(props)
    this.state={
      mode:"sites",
      showLevel:"all",
      loaded: false,
    }
    this.loadInfo()
    this.subscribe_newContextEvent=globs.events.subscribe("newContext",this.newContext)
    this.subscribe_refreshEvent=globs.events.subscribe("RefreshAlarmEntries",this.loadInfo)
  }
  
  loadInfo=async()=>{
//     cl(this.props.parms)
    let pa=this.props.parms
//     cl(pa)
    if(pa.siteId){
      await loadSiteData(pa.siteId)
      getZoneAlarmInfo(pa.siteId)
      await loadMBInfo()

      await makeSiteSensorNames(pa.siteId)
//       cl("loaded")
    }
    await loadAlarmsInfo()// gets active alarms
    // get tzo info
    this.setState({loaded:true})
//     cl(globs.alarmsInfo.info)
//     cl(globs.zonesInfo.info)
  }
  
  onChange=(type, vals)=>{
//     cl(type,vals)
    switch(type){
      case "level":
        this.setState(vals)
        break
    }
// this line was taken out 20230109:
//     this.subscribe_newContextEvent=globs.events.subscribe("newContext",this.newContext)
  }
  
  componentDidMount=()=>{
    this.mounted=true
  }
  componentWillUnmount=()=>{
    cl("unmounting")
    this.subscribe_newContextEvent.remove()
    this.subscribe_refreshEvent.remove();
    this.mounted=false
  }
  
  newContext=async(vals)=>{// page has changed
//     cl(vals)
    var p
    if(vals.level=="config"){
      p={level:vals.level,siteId:vals.siteId,zoneId:vals.zoneId,mode:"fui",pageType:vals.pageType,zuci:vals.zuci}
    }else{
      p={level:vals.level,siteId:vals.siteId,zoneId:vals.zoneId}
    }
//     await this.loadConfigLog(p)
//     this.setState({upd:(this.state.upd||0)+1,siteId:p.siteId,zoneId:p.zoneid})
  }
  
  showAlarmLevelSelector=()=>{
    return(
      <div className="user-toggle-field">
        <button className={`outlined ${(this.state.showLevel=="all")?" selected":""}`} type="button" 
          aria-pressed={`${(this.state.showLevel=="all")?"true":"false"}`} 
          onClick={e=>this.onChange("level",{showLevel:"all"})}>All</button>
        <button className={`outlined ${(this.state.showLevel=="13")?" selected":""}`} type="button" 
        aria-pressed={`${(this.state.showLevel=="13")?"true":"false"}`}
        onClick={e=>this.onChange("level",{showLevel:"13"})}>Levels 1-3</button>
        <button className={`outlined ${(this.state.showLevel=="47")?" selected":""}`} type="button" 
        aria-pressed={`${(this.state.showLevel=="47")?"true":"false"}`}
        onClick={e=>this.onChange("level",{showLevel:"47"})}>Level 4-6</button>
      </div>
    )
  }
  
  makeSensorAlarmCurrentLimit=(zi,type)=>{
//     cl(zi)
//     cl(type)
    let len=type.length
    if(type.substring(len-2,len)=="Sa"){
//       cl(type)
      let sensorType=type.substring(0,5)
      let hilo=type.substring(5,7)
      // cl(zi.current.sensorInfo)
      // cl(sensorType)
      let ai=zi.current.sensorInfo[sensorType]
      if(!ai){return[0,0]}
      let current=ai.val+ai.un
      let limit=((hilo=="Hi")?ai.ah:ai.al)+ai.un
//       cl([current,limit])
      return [current,limit]
//       cl(ai)
    }
//     cl("return null")
  }

  makeModbusAlarmCurrentLimit=(zi,type)=>{
    if(type.substring(0,2)=="MB"){
      cl(zi,type)
    }
  }
  
  makeCurrentLimit=(zi,type)=>{
//     if(zi.gatewayId=="2AJ810D8RZ0WAEKV"){cl([zi,type])}
    
    let current="--"
    let limit="--"
    var res
//     cl(type)
//     cl(zi.current)
    if(zi.current){
      res=this.makeSensorAlarmCurrentLimit(zi,type)||
        this.makeModbusAlarmCurrentLimit(zi,type)
      if(res){
        [current,limit]=res
      }else{
//         cl(type)
        let unit=+type.substring(1,2)
//         cl(unit)
        switch(type.substring(2)){
          case "intHi":
            
            current=`${zi.current[`inTemp${unit}`]}${zi.current.inTempUnit.t}`
            limit=`${zi.current.inTempHSP+zi.current.inTempHAL}${zi.current.inTempUnit.t}`
//             cl([current,limit])
            break
          case "intLo":
            current=`${zi.current.inTemp}${zi.current.inTempUnit.t}`
            limit=`${zi.current.inTempLSP-zi.current.inTempLAL}${zi.current.inTempUnit.t}`
            break
          default:
            break
        }
      }
    }
    return [current,limit]
  }
  
  getAlarmSensor=(alarmId)=>{
    let ty=alarmId.slice(-2)
    if(ty=="Sa"){
      return alarmId.substring(0,5)
    }
  }
  
  getAlarms=()=>{// on the site page, there *is* no zoneId
//     cl(this.props)
//     cl(this.state)
    let p=this.props.parms
    var zi
    // cl(p)
    let level=p.level
    if((level=="zone"||level=="config")&&p.sensor){level="sensor"}
    // cl(level)
    let alarms=[]
//     let zi=getZoneInfo(p.zoneId)
//     cl(p.zoneId)
//     cl(globs.zonesInfo.info)
//     let zInd=zi?.siteZoneIndex
    var get
    globs.alarmsInfo.info.forEach(a=>{// alarm has siteId and zoneIndex, *not* zoneId
//       cl(a)
      let zoneIndex=getZoneIndexFromSiteIndex(a.s,a.z)
//       if(a.z==0){cl([a,zoneIndex])}
      if(((zoneIndex==0)||zoneIndex)&&!globs.zonesInfo.info[zoneIndex].virtual){
        let zi=globs.zonesInfo.info[zoneIndex]
//         if(zi.gatewayId=="2AJ810D8RZ0WAEKV"){cl(zi)}
        
//         if(a.z==0){cl(zi)}
//         cl(zi)
        let [current,limit]=this.makeCurrentLimit(zi,a.a)
  //       cl(zi)
//         cl(a.a)
        // cl([current,limit])
        // cl(globs.zonesInfo.sz2z)
        // cl(a)
        let pr=privs("zone",globs.zonesInfo.sz2z[a.s][a.z]?.zoneId,constant.AREA_PRIVS_READ)
        // cl(pr)
        // cl(level)
        switch(level){
          case "zone":
            zi=getZoneInfo(p.zoneId)
            let zInd=zi?.siteZoneIndex
//             cl(pr)
//             cl(p.siteId,a.s)
            get=pr&&((p.siteId==a.s)&&(zInd==a.z))
//             cl(get)
//             if(p.siteId==a.s){cl(zi)}
//             if(get){cl("get*******************")}
            break
          case "site":
            get=pr&&(p.siteId==a.s)
            break
          case "account":
            get=pr&&true
            break
          case "sensor":
            pr=privs("zone",globs.zonesInfo.sz2z[a.s][a.z]?.zoneId,constant.AREA_PRIVS_READ)
            get=(pr&zi.zoneId==p.zoneId)&&(p.sensor==this.getAlarmSensor(a.a))
            
            break
          default:
            break
        }
  //       if(get){cl(a)}
        let ai=getAlarmInfo(a.a,a.s,a.z)||{}
        switch(this.state.showLevel){
          case "all":
            if(ai.level>7){get=null}
            break
          case "13":
            if(ai.level>3){get=null}
            break
          case "47":
            if((ai.level>7)||(ai.level<4)){get=null}
            break
        }
        let name=(level=="zone")?ai.name:`${zi.zoneName}-${ai.name}`
        if(get){
          // modify date with tzo and dst
          let tzo = getTzo(zi)
          let dst = getDst(zi)
          // cl([globs.zonesInfo.info[a.z], tzo, dst, new Date(((a.b + (tzo * 60)*1000))), new Date((a.b + (tzo * 60) + (dst * 60))*1000)])
          let da=new Date(tzo ? ((a.b + (tzo * 60) + (dst * 60 * 60))*1000): a.b * 1000)
          alarms.push({
            aId:a.a,
            level:ai.level,
            // time:dateToDisplayDate(da,"h:mm AP"),
            date: (tzo) ? `${dateToDisplayDate(da,"mm/dd/yyyy h:mm ap", 0)} ${getTZIndicator(tzo)}` : dateToDisplayDate(da,"mm/dd/yyyy h:mm ap"),
            da:da,
            name:name,
            current:current,
            limit:limit,
            siteId:a.s,
            zoneId:globs.zonesInfo.sz2z[a.s][a.z]?.zoneId,
          })
        }
      }
    })
    alarms.sort((a,b)=>{
      if(a.da<b.da){return 1}
      if(a.da>b.da){return -1}
      return 0
    })
    // cl(alarms)
    return alarms
  }
  
  showAlarms=()=>{
//     let alarms=[
//       {
//         level:2,
//         time:"8:23 AM",
//         date:"05/17/2021",
//         name:"Inside Light Really Long Alarm Name and then Some",
//         current:"1000 w/m2",
//         limit:"700 w/m2",
//       },
//       {
//         level:4,
//         time:"8:23 AM",
//         date:"05/17/2021",
//         name:"Inside Light",
//         current:"1000 w/m2",
//         limit:"700 w/m2",
//       },
//       {
//         level:4,
//         time:"8:23 AM",
//         date:"05/17/2021",
//         name:"Inside Light",
//         current:"1000 w/m2",
//         limit:"700 w/m2",
//       },
//     ]
//     cl(this.props)
    let alarms=this.getAlarms()
//     cl(alarms)
    
    if (alarms.length === 0) {
      return (
        <div style={{textAlign:"center"}}>No Alarms</div>
      )
    }

    return alarms.map((a,i)=>{
      // cl(a)
      if(a.level<7){
        let p=this.props.parms
        let gotCurrent=a.aId.substring(2,4)!="re"
        gotCurrent=gotCurrent&&(a.aId.substring(2,7)!="intSn")
        
//         cl(gotCurrent)
        // cl(a.aId)
        let page=(alarmToSensor(a.aId)) ? `/sensor/${alarmToSensor(a.aId)}` : "/SPalarms"
        let url=(a.zoneId) ? `/usa/c18/sites/${a.siteId}/zones/${a.zoneId}${page}` : `/usa/c18/sites/${a.siteId}/SPalarms`
//         cl(url)
        return(
          <div key={i}>
            <div className="info-section">
              <div className="floatleft">
              
                <span className={`small-alarm-icon${(a.level<4)?" urgent-alarm":""}`}>
                  <span className="material-icons-outlined">notifications_active</span> <span className="alarm-level">{a.level}</span>
                </span> 
                
                <C18Anchor00 to={url}>{a.name}</C18Anchor00>

              </div>
              <div className="floatright time-date top-margin-verysmall">
                <span className="date">{a.date}</span>
              </div>
              {(((p.level!="account")&&gotCurrent)&&
                <>
                  <div className="clearfloat"></div>
                  <div className=" top-margin-small"></div>
                  <div className="floatleft">
                    <span className="small-text">CURRENT</span><br />
                    <span className="large-text alarm-on">{a.current}</span>
                  </div>
                  <div className="floatright">
                    <span className="small-text">LIMIT</span><br />
                    <span className="large-text">{a.limit}</span>
                  </div>
                </>
              )}
            </div>
            <div className="clearfloat"></div>
            <br /><hr /><br />
          </div>
        )
      }
      })
    
  }
  
  render(){
    if(this.state.loaded){
      return(
        <div id="sidebar-alarms">
          {this.showAlarmLevelSelector()}
          {this.showAlarms()}
        </div>
      )
    }else{
      return <div id="content-area">loading zone list. . .</div>
    }
  }
}
      
export default C18SidebarAlarms00;
