import React from 'react';
import C18Select00 from './C18Select00'
import C18Anchor00 from './C18Anchor00'
import C18Select01 from './C18Select01'
import C18Input00 from './C18Input00'
import C18Button00 from './C18Button00'
import C18SubMenuHeader00 from './C18SubMenuHeader00'
// import C18SiteZoneView00 from './C18SiteZoneView00'
import {wsTrans,doGetPostBasic} from '../utils/utils'
import {loadSitesInfo,loadZonesInfo,loadSummaryPresetsInfo,loadUsersInfo,getSiteName,getZoneName,getUserIndex,getSummaryPresetIndex,loadPrivsInfo,privs} from './C18utils'
import {cl,globs,constant,dateToDisplayDate, addNumbering} from '../../components/utils/utils';

class C18Support00 extends React.Component{
  constructor(props) {
    super(props);
    this.state={
      loaded: false,
      selTicket:-1,
      pageType:"userCreate",
      userProduct:"iGrow800",
      // userPage:"userCreate",
      userFirstName:"",
      userLastName:"",
      userPhone:"",
      userEmail:"",
      userSubject:"",
    }
    this.setBreadCrumbs()
    this.loadInfo()
  }

  setBreadCrumbs=()=>{
    if(this.props.parms){
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: [
              {t:"Sites", url:"/usa/c18/sites"},
              {t:"Support", url:`/usa/c18/admin/support`},
            ]},
        },
      )
    }
  }

  loadInfo=async()=>{
    await loadZonesInfo()
    this.setState({loaded:true})
  }

  doTest=async()=>{
    let body={test:"test"}
    // let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/support", method: "update",
    //   sessionId: globs.userData.session.sessionId, body: body})
    // cl(res.data)

    // https://accounts.zoho.com/oauth/v2/token?code=1000.f15be3b2d00ebd4cb7e80c509e71a212.f078806107e40b73ed5cf19151827eef&grant_type=authorization_code&client_id=1000.852EDPYK7ZUVDICWXA9L5G1BN9O07E&client_secret=fdd868d877cc3a2991b269ba75f1b220eea4c2b3d1&redirect_uri=https://c2.link4cloud.com


    // let url=`https://accounts.zoho.com/oauth/v2/token`
    // let data = new FormData()
    // data.append("code", "xx")
    // data.append("grant_type", "authorization_code")
    // data.append('client_id', "1000.852EDPYK7ZUVDICWXA9L5G1BN9O07E")
    // data.append('clientSecret', "fdd868d877cc3a2991b269ba75f1b220eea4c2b3d1")
    // data.append('redirect_uri', "https://c2.link4cloud.com")
    // let method="POST"
    // let type="multipart/form-data"
    // cl(url,method,data,type)
    // let ret=await doGetPostBasic(url, method, data, type)
    // let text=await ret.text()
  }

  getTicketHistory=async(vals)=>{
    let st=this.state
    cl(st.tickets,st.selTicket)
    let ticketId=st.tickets[vals.selTicket].id
    let body={cmd:"getTickets",type:"history",id:ticketId}
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/support", method: "retrieve",
      sessionId: globs.userData.session.sessionId, body: body})
    cl(res)
    cl(res.data.tickets)

  }


  getTickets=async()=>{
    let body={cmd:"getTickets"}
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/support", method: "retrieve",
      sessionId: globs.userData.session.sessionId, body: body})
    cl(res)
    this.setState({showTickets:true,tickets:res.data.tickets})
  }

  onChange=(type,vals)=>{
    cl(type,vals)
    switch(type){
      case "Test":
        cl("test")
        this.doTest()
        // globs.events.publish("savePageEnable",true)
        // Object.assign(this.sitesInfo[getSiteIndex(this.state.siteSel)],vals)
        // this.mySetState(vals)
        break
      case "getTickets":
        this.getTickets()
        break
      case "selTicket":
        this.getTicketHistory(vals)
        this.setState(vals)
        break
      case "pageType":
        cl()
        this.setState(vals)
        break
      case "userProduct":
        this.setState(vals)
        break
      case "userFirstName":
        this.setState(vals)
        break
      case "userLastName":
        this.setState(vals)
        break
      case "userPhone":
        this.setState(vals)
        break
      case "userEmail":
        this.setState(vals)
        break
      case "userSubject":
        this.setState(vals)
        break
      case "userDescription":
        this.setState(vals)
        break
      case "userCreateBut":
        this.userCreate()
        break
    }
  }

  showTickets=()=>{
    let st=this.state
    if(!st.showTickets){return}
    cl(st)
    let rows=st.tickets.map((t,i)=>{
      let line=t.subject
      let fields=line.split(" / ")
      // cl(fields)
      var subj
      var cust=""
      var type=fields[0]
      if(fields[0].substring(0,25)=="Inbound Missed Call from "){
        type="Missed"
        line=t.subject.slice(25)
      }else{
        if(["6AM TIME CALL","SALE","REPAIR","RMA"].includes(fields[0])){
          fields=fields.slice(1)
        }
        type=type.replace("iPonic ","IP")
        type=type.replace("iGrow ","IG")
        subj=fields.slice(1).join("/")
        cust=(fields[2]||"").substring(0,12)
        line=fields.filter((f,i)=>{return ![0,2].includes(i)}).join("/")
      }
      // cl(i,st.selTicket)
      let bgColor=(i==st.selTicket)?"#CCFFFF":"white"
      // cl(st)

      return(
        <tr key={i} style={{backgroundColor:bgColor}}
        onClick={x=>this.onChange("selTicket",{selTicket:i})}
        >
        <td style={{whiteSpace:"nowrap"}}>{cust}</td>
        <td style={{whiteSpace:"nowrap"}}>{type.substring(0,8)}</td>
        <td style={{whiteSpace:"nowrap"}}>{line}</td>
        </tr>
      )

    })
    return(
      <div style={{width:400,height:700,padding:10,border:"1px solid black",
        borderRadius:10,overflowY:"auto"}}>
        <table width="100%" style={{cursor:"pointer"}}><tbody>
        {rows}
        </tbody></table>
      </div>
    )
  }

  showTicketDetail=()=>{
    let st=this.state
    if(st.selTicket<0){return}
    let ticket=st.tickets[st.selTicket]
    cl(ticket)
    let da=new Date(Date.parse(ticket.createdTime))
    cl(da)
    let subj=ticket.subject.split(" / ").map((s,i)=>{return <span key={i}>{s}<br/></span>})
    return(
      <div style={{padding:10,border:"1px solid black", borderRadius:10}}>
        <table><tbody>
        <tr><td>
        Created:
        </td><td>
        {ticket.createdTime}
        </td></tr>
        <tr><td>
        Channel:
        </td><td>
        {ticket.channel}
        </td></tr>
        <tr><td>
        Phone:
        </td><td>
        {ticket.phone}
        </td></tr>
        <tr><td valign="top">
        Subject:
        </td><td>
        {subj}
        </td></tr>
        <tr><td>
        <a href={ticket.webUrl} target="_blank" style={{color:"blue",cursor:"pointer"}}>
          Open&nbsp;Zoho</a>
        </td><td>
        </td></tr>
        </tbody></table>
      </div>
    )
  }

  showTest=()=>{
    return(
      <div>
        <C18Button00 type="button" className="outlined" onClick={()=>this.onChange("Test")}>Test</C18Button00><br/><br/>
        <C18Button00 type="button" className="outlined" onClick={()=>this.onChange("getTickets")}>Get Tickets</C18Button00><br/><br/>
      </div>
    )
  }

  showSupportPage=()=>{
    return(
      <div>Support<br/><br/>
      {this.showTest()}
      <table><tbody>
        <tr><td width="400">
        {this.showTickets()}
        </td><td width="20">
        </td><td align="left" valign="top">
        {this.showTicketDetail()}
        </td></tr>
      </tbody></table>
      </div>
    )
  }

  showProductSelect=()=>{
    let st=this.state
    let opts=[
      {t:"iGrow 800",v:"iGrow800"},
      {t:"iPonic 614",v:"iPonic614"},
      {t:"iPonic 624",v:"iPonic624"},
      {t:"iGrow 1800",v:"iGrow1800"},
      {t:"Pearl",v:"pearl"},
      {t:"Expansion Board",v:"xBoard"},
      {t:"Other",v:"Other"},
      {t:"iDoser",v:"iDoser"},
      {t:"Watchdog",v:"watchDog"},
      {t:"iDrive",v:"iDrive"},
      {t:"iGrow 1200",v:"iGrow1200"},
      {t:"iGrow 1400",v:"iGrow1400"},
      {t:"iGrow 1600",v:"iGrow1600"},
      {t:"iGrow 400",v:"iGrow400"},
      {t:"iPonic Alert",v:"iPonicAlert"},
      {t:"iDrive AC",v:"iDriveAc"},
      {t:"iDrive DC",v:"iDriveDc"},
      {t:"Gen 13 Lighting Panel",v:"gen13Light"},
      {t:"Gen 14 Lighting Panel",v:"gen14Light"},
      {t:"Standard Contactor Panel",v:"contPanel"},
      {t:"Sapphire",v:"sapphire"},
    ]
    // cl(st)
    return(
      <div>
        <C18Select01 parms={{
          label:"Select Product",
          valueName:"userProduct",
          userProduct:(st.userProduct||0),
          opts:opts,
          onChange:(e,v)=>{this.onChange("userProduct",v)}//this.onChange,
        }}/>
      </div>
    )
  }

  showUserContact=()=>{
    let st=this.state
    return(
      <div>
        <table style={{width:"initial"}}><tbody>
        <tr><td>
          <label htmlFor="firstName">First Name</label>
          <C18Input00 type="text" id="firstName"
            value={st.userFirstName||""}
            onChange={e=>this.onChange("userFirstName",
              {userFirstName:e.currentTarget.value})}
          />
        </td><td width="20">
        </td><td>
          <label htmlFor="lastName">Last Name</label>
          <C18Input00 type="text" id="lastName"
            value={st.userLastName||""}
            onChange={e=>this.onChange("userLastName",{userLastName:e.currentTarget.value})}
          />
        </td></tr>
        </tbody></table><br/>
      </div>
    )
  }

  showUserPhone=()=>{
    let st=this.state
    return(
      <div>
        <label htmlFor="phone">Phone</label>
        <C18Input00 type="text" id="phone"
          value={st.userPhone||""}
          onChange={e=>this.onChange("userPhone",{userPhone:e.currentTarget.value})}
        /><br/>
      </div>
    )
  }

  showUserEmail=()=>{
    let st=this.state
    return(
      <div>
        <label htmlFor="email">Email</label>
        <C18Input00 type="text" id="email"
          value={st.userEmail||""}
          onChange={e=>this.onChange("userEmail",{userEmail:e.currentTarget.value})}
        /><br/>
      </div>
    )
  }

  showUserSubject=()=>{
    let st=this.state
    return(
      <div>
        <label htmlFor="subject">Subject</label>
        <C18Input00 type="text" id="subject"
          value={st.userSubject||""}
          onChange={e=>this.onChange("userSubject",{userSubject:e.currentTarget.value})}
        /><br/>
      </div>
    )
  }

  showUserDescription=()=>{
    let st=this.state
    return(
      <div>
        <label htmlFor="description">Please Describe the Issue</label>
        <textarea id="description" style={{resize:"none"}}
          cols="40"
          rows="8"
          value={st.userDescription}
          onChange={e=>this.onChange("userDescription",
            {userDescription:e.currentTarget.value})}
        />
        <br />
      </div>
    )
  }

  userCreate=async()=>{
    let st=this.state
    cl("user create")
    let body={
      cmd:"createTicket",
      product:st.userProduct,
      firstName:st.userFirstName,
      lastName:st.userLastName,
      phone:st.userPhone,
      email:st.userEmail,
      subject:st.userSubject,
      description:st.userDescription,
      // account:st.userAccount,
    }
    cl(body)
    let res=await wsTrans("usa", {cmd: "cRest", uri: "/s/support", method: "update",
      sessionId: globs.userData.session.sessionId, body: body})
  }


  showUserCreatePage=()=>{
    return(
      <div>
        {this.showProductSelect()}
        {this.showUserContact()}
        {this.showUserPhone()}
        {this.showUserEmail()}

        {this.showUserSubject()}
        {this.showUserDescription()}
        <C18Button00 type="button" className="filled"
          onClick={e=>{this.onChange("userCreateBut")}}
        >Create</C18Button00><br/>
      </div>
    )
  }

  showUserViewPage=()=>{
    return(
      <div>
      View
      </div>
    )
  }

  showUserKbPage=()=>{
    let lStyle={color:"#444488",fontSize:18,cursor:"pointer"}
    return(
      <div>
        <h1 style={{marginLeft:0}}>These items are available in the Knowledge Base:</h1>
        <div>
<h2>iGrow800</h2>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/igrow-800-connecting-to-link4-igrow-cloud-25-4-2018.pdf" target="_blank">iGrow 800 - Connecting to Link4 iGrow Cloud</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/igrow-1000-viewing-multiple-controllers-at-one-time.pdf" target="_blank">iGrow 1000 Viewing Multiple Controllers at one time</a><br/>


<h2>iPonic 614 and 624</h2>
<h3>Getting Started</h3>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-624-mobile-video.pdf" target="_blank">iPonic 624 Mobile Video</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/preview-the-new-iponic-624.pdf" target="_blank">Preview the New iPonic 624</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-614-quick-start-guide.pdf" target="_blank">iPonic 614 Quick Start Guide</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/easily-set-up-your-equipment-with-the-iponic-614.pdf" target="_blank">Easily Set Up Your Equipment With The iPonic 614!</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-control-strategy.pdf" target="_blank">iPonic Control Strategy</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-624-overview-and-general-understanding.pdf" target="_blank">iPonic 624 Overview and General Understanding</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-624-equipment-change-part-i-video.pdf" target="_blank">iPonic 624 Equipment Change Part I Video</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-624-equipment-change-part-ii-video.pdf" target="_blank">iPonic 624 Equipment Change Part II Video</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/answering-your-first-ticket.pdf" target="_blank">Answering your first ticket.</a><br/>
<h3>Lighting</h3>

<a style={lStyle} href="https://downloads.link4cloud.com/kb/adjusting-light-settings-iponic-614.pdf" target="_blank">Adjusting Light Settings - iPonic 614</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-light-issues-troubleshooting-26-1-2018.pdf" target="_blank">iPonic Light Issues & Troubleshooting</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-light-issues-troubleshooting.pdf" target="_blank">iPonic Light Issues Troubleshooting</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-624-light-output-running-24-7.pdf" target="_blank">iPonic 624 Light Output Running 24/7</a><br/>
<h3>Irrigation</h3>

<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-irrigation-information.pdf" target="_blank">iPonic Irrigation Information</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-cycle-irrigation-understanding.pdf" target="_blank">iPonic Cycle Irrigation Understanding</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-irrigation-programming-equipment-information.pdf" target="_blank">iPonic Irrigation & Programming Equipment information</a><br/>
<h3>Power</h3>

<a style={lStyle} href="https://downloads.link4cloud.com/kb/24v-power-supply-to-the-iponic-channel.pdf" target="_blank">24V Power Supply to the iPonic Channel</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-power-issues.pdf" target="_blank">iPonic Power Issues</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-power-issues-26-1-2018.pdf" target="_blank">iPonic Other Power Issues</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-power-issues-flickering-light-led-or-no-power-at-all.pdf" target="_blank">iPonic Power Issues - Flickering LIght LED or NO POWER at all</a><br/>
<h3>Heating, Cooling, and Fans</h3>

<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-614-adjusting-cooling-heating-setpoints.pdf" target="_blank">iPonic 614 - Adjusting Cooling & Heating Setpoints</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/switch-between-degrees-fahrenheit-and-degrees-celsius-iponic-614.pdf" target="_blank">Switch Between Degrees Fahrenheit and Degrees Celsius - iPonic 614</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/controlling-temperature-with-setpoints-iponic-614.pdf" target="_blank">Controlling Temperature with Setpoints - iPonic 614</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/setting-up-ventilation-fans-iponic-614.pdf" target="_blank">Setting Up Ventilation Fans - iPonic 614</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-cycling-fans-other-equipment-using-the-irrigation-equipment-type.pdf" target="_blank">iPonic Cycling Fans & Other Equipment using the Irrigation Equipment Type</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/setting-up-central-heating-and-air-iponic-614.pdf" target="_blank">Setting Up Central Heating and Air - iPonic 614</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/setting-up-a-heater-iponic-614.pdf" target="_blank">Setting Up a Heater - iPonic 614</a><br/>

<h3>Co2</h3>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/changing-your-co2-target-iponic-614.pdf" target="_blank">Changing Your CO2 Target - iPonic 614</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/setting-up-a-co2-injector-iponic-614.pdf" target="_blank">Setting Up A CO2 Injector - iPonic 614</a><br/>

<h3>Troubleshooting</h3>

<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-614-dism-troubleshooting.pdf" target="_blank">iPonic 614 DISM Troubleshooting</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-cloud-alarm-notification-setup-tutorial.pdf" target="_blank">iPonic Cloud Alarm & Notification Setup Tutorial</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-communications-troubleshooting.pdf" target="_blank">iponic Communications Troubleshooting</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-froze-state-solution.pdf" target="_blank">iPonic Froze State Solution</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-614-using-outputs-7-8.pdf" target="_blank">iPonic 614 using Outputs 7 & 8</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/iponic-624-firmware-step-by-step-process.pdf" target="_blank">iPonic 624 Firmware Step by Step Process</a><br/>

<h3>Others</h3>

<a style={lStyle} href="https://downloads.link4cloud.com/kb/sensaphone-troubleshooting.pdf" target="_blank">Sensaphone Troubleshooting</a><br/>
<a style={lStyle} href="https://downloads.link4cloud.com/kb/integrated-panels.pdf" target="_blank">Integrated Panels</a><br/>

      </div>
      </div>
    )
  }

  showUserMenu=()=>{
    return(
      <div>
        <C18SubMenuHeader00 parms={{
          items:[
            {v:"userCreate",t:"Create a Ticket"},
            {v:"userView",t:"View Tickets"},
            {v:"userKb",t:"Knowledge Base"},
            {v:"support",t:"Support"},
          ],
          pageType: this.state.pageType,
          onChange: o=>this.onChange("pageType",o),
        }}/>
          <div className="clearfloat"/><br/>
      </div>
    )
  }

  render(){
    let st=this.state
    cl(st)
    let pageTypes={
      userCreate:this.showUserCreatePage,
      userView:this.showUserViewPage,
      userKb:this.showUserKbPage,
      support:this.showSupportPage,
    }
    if(this.state.loaded){
      return(
        <div>
          {this.showUserMenu()}
          {pageTypes[st.pageType]()}
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}

export default C18Support00;
