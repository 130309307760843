import React, { useEffect } from 'react';
import {getParamId2,wsTrans,doGetPost} from '../utils/utils'
import {cl,globs,constant,getLocalStorage, allModuleTypes,intToFiveBit,
  fiveBitsToInt,getRandomInt,copyToClipboard} from '../../components/utils/utils';
import C18SubMenuHeader00 from './C18SubMenuHeader00'
import C18Button00 from './C18Button00'
import C18Select00 from './C18Select00'
import {doGetPostBasic} from '../utils/utils'
var config = require('../../components/utils/config');
// cl(config)

function MyComponent() {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://externalwebsite.com/externalFile.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div> response
    </div>
  );
}


class C18QrCodeReceive00 extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      tabs:"Production",
      deviceType:null,
      qrcode:null,
      uid:null,
      selModuleType:-1,
      selModuleName:"",
      selAccount:"",
      selSite:"",
      selZone:"",
      selModule:null,
      moduleAddr:-1,
      hwVers:1,
      accounts: [],
      sites: [],
      zones: [],
      allModules: [],
      modules: [],
      sortMode: 1,
      errorMsg: "",
      statusMsg: "",
      addModuleCode:"",
      enabled:true,
      copyText: "",
      searchModuleTxt: "",
    }
    this.setQrCodeListener()
    this.subscribe_savePageEvent=globs.events.subscribe("savePageEvent",this.saveModule)
    this.loadInfo()
    this.bgColor2=(this.dark)?"#203030":"#FFFFFF"
    this.selColor1=(this.dark)?"#606060":"#DDDDDD"
    this.varModuleAddr = "-10"
    this.noModuleAddr = "-100"
    this.modAddrRange={
      dismp:[0,1], // mb for Pearl 
      dismprs:[0,1], // rs for iGrow
      dth5G0:[2,3],
      dth5L0:[2,3],
      ws5in:[4,5],
      ws5int:[4,5],
      "8outI2C":[6,21],
      "8outI2C-DS":[6,21],
      "1uin":[22,37],
      "4a4d":[38,39],
      "1u4out":[40,41],
      "1u4aout":[42,43],
    }

  }

  componentWillUnmount=async()=>{
    this.subscribe_savePageEvent.remove()
    await wsTrans("usa", {cmd: "cRest", uri: "/s/qrcodeListeners", method: "delete",
      sessionId: globs.userData.session.sessionId,body: {}})
    this.subscribe_qrcodeReceived.remove()
  }

  onChange=async(type,vals)=>{
//     cl(type,vals)
    switch(type){
      case "sel":
        if ("selAccount" in vals) {
          Object.assign(vals, {selSite: ""}, {selZone: ""})
        } else if ("selSite" in vals) {
          Object.assign(vals, {selZone: "", selAccount: this.state.selAccount})
        } else if ("selZone" in vals) {
          Object.assign(vals, {selAccount: this.state.selAccount, selSite: this.state.selSite})
        }
        if (vals.selAccount && vals.selSite && vals.selZone) {
          if (this.state.selModule) {
            globs.events.publish("savePageEnable",true)
          }
        }
        this.setState(vals)
        break
      case "switchTab":
        if (this.state.tabs != vals.pageType) {
          this.setState({tabs: vals.pageType, errorMsg: "", statusMsg: ""})
        }
        break
      case "sort":
        this.sortModules(vals.column)
        break
      case "itemClick":
        delete vals._id
        Object.assign(vals, {uid: vals.selModule.uid, selModuleName: vals.selModule.name, qrcode: vals.selModule.qrcode, selModuleType: vals.selModule.type})
        this.setState(vals)
        break
      case "testProdST":
        this.testProdST()
        break
      case "createCode":
        this.createCode()
        this.setState({copyText: ""})
        break
//       case "addModuleCode":
//         this.setState(vals)
//         break
      case "searchModuleTxt":
        // do search of cached values using UID and Label code
        // convert input to label code
        // strip of dashes
        this.setState(vals)
        break
      case "addModuleFromCode":
        this.addModuleFromCode()
        break
      case "editName":
        if (this.state.selModule && this.state.selAccount && this.state.selSite && this.state.selZone) {
          globs.events.publish("savePageEnable",true)
        }
      case "hwVers":
        cl(vals)
        Object.assign(vals, {uid: "", qrcode: ""})
        this.setState(vals)
        break
      case "selModuleType":
        // cl(vals.selModuleType)
        vals.enabled=(this.curFW[vals.selModuleType]?.length>0)
        Object.assign(vals, {uid: "", qrcode: ""})
        this.setState(vals)
        break
      case "moduleAddr":
        // chooses from range if variable address is selected
        // clear range too
        Object.assign(vals, {uid: "", qrcode: ""})
        this.setState(vals)
        break
      case "copyLabel":
        let success = await copyToClipboard(vals.labCode)
        cl(success)
        // show "success" if copied correctly to clipboard
        setTimeout(() => {
          this.setState({copyText: ""})
        }, 5000);
        if (success) {
          this.setState({copyText: "Copied to clipboard!"})
        } else {
          this.setState({copyText: "Error copying text."})
        }

        break
      default:
        this.setState(vals)
        break
    }
  }

  filterAccount=(module)=>{
    return this.state.selAccount == "" || module.accountId?.includes(this.state.selAccount)
  }

  filterSite=(module)=>{
    return this.state.selSite == "" || module.siteId?.includes(this.state.selSite)
  }

  filterZone=(module)=>{
    return this.state.selZone == "" || module.zoneId?.includes(this.state.selZone)
  }

  filterLabel=(module)=> {
    cl([module.qrcode, intToFiveBit(module.qrcode), this.state.searchModuleTxt])
    let searchModuleTxt = this.state.searchModuleTxt.replace("-", "")
    return this.state.searchModuleTxt == "" || module.qrcode && intToFiveBit(module.qrcode).indexOf(searchModuleTxt)>=0
  }

  filterUid=(module)=> {
    return this.state.searchModuleTxt == "" || module.uid && module.uid.toString(16).indexOf((this.state.searchModuleTxt||""))>=0
  }

  filterName=(module)=> {
    return this.state.searchModuleTxt == "" || module.name && module.name.indexOf((this.state.searchModuleTxt||""))>=0
  }

  filterType=(module)=> {
    return this.state.searchModuleTxt == "" || module.type && module.type.indexOf((this.state.searchModuleTxt||""))>=0
  }

  filterModules=()=>{
    let modules=this.state.allModules.filter(us=>{
      let f1=this.filterLabel(us)
      let f2=this.filterUid(us)
      let f3=this.filterName(us)
      let f4=this.filterType(us)
      let f5=this.filterAccount(us)
      let f6=this.filterSite(us)
      let f7=this.filterZone(us)
      return (f1||f2||f3||f4) && f5 && f6 && f7
    })
    modules = this.doSortModules(this.state.sortMode, modules)
    return modules
  }

  gotQrCode=async(msg)=>{
    cl(msg)
    if(msg.code){msg.qrcode=msg.code; delete msg.code}
    // make call to backend for qrcode to get selected qrcode
    // if qr code exists, save to selected module
    if (this.state.tabs == "Production") {
      this.setState({qrcode: msg.qrcode})
    } else if (this.state.tabs == "Installation") {
      let module = (await wsTrans("usa", {cmd: "cRest", uri: "/s/moduleIds", method: "retrieve",sessionId: globs.userData.session.sessionId,
        body: msg})).data
      // cl(module)
      if (module.error) {
        this.setState({errorMsg: `Error on fetch: ${module.error}`, statusMsg: ""})
      } else {
        module = module[0]
        delete module._id
        let vals = {
          selModule: module, 
          qrcode: module.qrcode,//msg.code,
          statusMsg: `Fetch for qr code ${module.qrcode} found a module!`,
          errorMsg: "",
          uid:module.uid,

          selAccount: module.accountId || this.state.selAccount, 
          selSite: module.siteId || this.state.selSite, 
          selZone: module.zoneId || this.state.selZone,
          selModuleName: module.name || "",
        }
        if (module.accountId && module.siteId && module.zoneId) {
          let modules = (await wsTrans("usa", {
            cmd: "cRest", 
            uri: "/s/moduleIds", 
            method: "retrieve",
            sessionId: globs.userData.session.sessionId, 
              body: {
                accountId: vals.selAccount,
                siteId: vals.selSite,
                zoneId: vals.selZone,
              }
            })).data
            if (modules.error) {
              cl(`error: ${modules.error}`)
            } else {
              Object.assign(vals, {modules: modules})
            }
        }
        this.setState(vals)
      }
    }
  }

  sortModules=(col)=>{ 
    var sortMode
    if(Math.abs(col)==Math.abs(this.state.sortMode)){
      sortMode=0-this.state.sortMode
    }else{
      sortMode=col
    }
    this.setState({sortMode:sortMode,modules:this.doSortModules(sortMode,this.state.modules.slice(0))})
  }

  doSortModules=(sortMode,modules)=>{
    modules.sort((a,b)=>{
      let dir=(sortMode>0)?1:-1
      switch(Math.abs(sortMode)){
        case 1:// module name
          let aName = a.name?.toLowerCase() || ""
          let bName = b.name?.toLowerCase() || ""
          if(aName<bName){return 0-dir}
          if(aName>bName){return dir}
        case 2:// module type
          let aType = a.type
          let bType = b.type
          if(aType<bType){return 0-dir}
          if(aType>bType){return dir}
        case 3:// # module uid
          let aUid = a.uid?.toString(16)
          let bUid = b.uid?.toString(16)
          if(aUid<bUid){return 0-dir}
          if(aUid>bUid){return dir}
        case 4:// label code
          let aQrcode = intToFiveBit(a.qrcode)||""
          let bQrcode = intToFiveBit(b.qrcode)||""
          if(aQrcode<bQrcode){return 0-dir}
          if(aQrcode>bQrcode){return dir}
      }
    }).slice(0)
    return modules
  }

  findModule=async(query)=>{
    return await wsTrans("usa", {cmd: "cRest", uri: "/s/moduleIds",
      method: "retrieve",
      sessionId: globs.userData.session.sessionId,
      body: query})
  }

  createCode=async()=>{
//     cl(this.state)
    let st=this.state
    var qrCode
    while(true){
      qrCode=getRandomInt()&0x3FFFFFFF // 30 bits
      let res=await this.findModule({qrcode:qrCode})
//       cl(res)
      if(res.result=="notFound"){break}
    }
    let moduleAddr = st.moduleAddr
    if (moduleAddr === this.varModuleAddr) {
      if(this.state.selModuleType!=-1){
        let range=this.modAddrRange[this.state.selModuleType]
        moduleAddr = 128 + Math.floor(Math.random() * (range[1] - range[0] + 1) + range[0])
      } else {
        moduleAddr = -1
      }
    }
    let uid=getRandomInt()&0xFFFFFFC0|(moduleAddr)
    if(uid<0){uid+=0x100000000}
//     cl(uid)
    if (moduleAddr == this.noModuleAddr) {
      uid = -1
      qrCode = -1
    }
    this.setState({qrcode:qrCode,uid:uid, selectedModuleAddr: moduleAddr})
  }

  readLabelCode=(str)=>{// read as a qr code
    str=str.toUpperCase()
    str=str.replaceAll("B","8")
    str=str.replaceAll("I","1")
    str=str.replaceAll("O","0")
    str=str.replaceAll("S","5")
    str=str.replaceAll("-", "")
    // if(str[3]=="-"){
    //   let str=str.substring(0,3)+str.substring(4,7)
    // }
    if(str.length==6){
      return fiveBitsToInt(str)
    }
    if(!isNaN(str)){return +str}
  }

  addModuleFromCode=async()=>{
    let st=this.state
    let code=st.addModuleCode
    let qrCode=this.readLabelCode(code)
    cl(qrCode)
    let res=await this.findModule({qrcode:qrCode||""})
    cl(res)
    if(res.result=="notFound"){
      let uid=parseInt(code,16)
      cl(uid)
      this.gotQrCode({uid:uid})
    }else{
      this.gotQrCode({code:qrCode})
    }
    cl(qrCode)
  }

  makeRemoteFW=(guid)=>{
//     cl("make remote")
    let type=this.state.selModuleType
    let code=guid
    let labCode
    if (this.state.qrcode != -1) {
      let lcode=intToFiveBit(this.state.qrcode)
      labCode= `${lcode.substring(0,3)}-${lcode.substring(3,6)}`
    } else {
      labCode= "NO_UID"
    }
    let url=`${constant.expressUrl}/usa/fw/ModuleFirmware.txt?type=${type}&code=${code}&label=${labCode}`
    // cl(url)
//     window.open(url,'_blank')
    window.location.href=url
    return "ok"
  }

  makeContinue=true

  makeCounts=()=>{
    return [
      {
        type:"8outI2C",
        count:60,
      },
      {
        type:"1uin",
        count:30,
      },
      {
        type:"dth5L0",
        count:30,
      },
      {
        type:"dth5G0",
        count:30,
      },
      {
        type:"4a4d",
        count:30,
      },
    ]
  }

  makeModCount=0

  makeOneMod=async(type,id)=>{
    return new Promise(async(r,e)=>{
      let st=this.state
      let qrCode=getRandomInt()&0x3FFFFFFF // 30 bits
      let uid=getRandomInt()
      let name=`${type}_${id}.txt`
      let query={
        qrcode:qrCode,
        type:type,
        hwVers:st.hwVers,
        uid:uid}
//         cl(query)
      await wsTrans("usa", {cmd: "cRest", uri: "/s/moduleIds", method: "create",
        sessionId: globs.userData.session.sessionId, body: query})
      cl(type,id)
      let url=`${constant.expressUrl}/usa/fw/ModuleFirmware.txt?type=${type}&code=${uid}&name=${name}`
//       cl(url)
      window.location.href=url
//       if(this.makeModCount++ > 10){this.makeContinue=false}
      setTimeout(x=>{r()},1000)
    })
  }

  makeBatchModules=async()=>{
//     return
    let toMake=this.makeCounts()
    for(let j=0;j<toMake.length;j++){
      let m=toMake[j]
      for(let i=0;i<m.count;i++){
        if(this.makeContinue){
//           cl("make")
          await this.makeOneMod(m.type,i+1)
//           cl("made")
        }
      }
    }
  }

  makeLabelCode=(qrcode)=>{
    let code=intToFiveBit(qrcode)
    let labCode=`${code.substring(0,3)}-${code.substring(3,6)}`
    return labCode
  }

  createBinary=async()=>{
    let st=this.state
//     cl([this.state.selModuleType, this.state.qrcode])
    // if a pearl module
    var status
    var msg
    var name
    var labCode
    // use qr code and type to generate binary
    // send qr code to backend and receive uid
    let module = (await wsTrans("usa", {cmd: "cRest", uri: "/s/moduleIds", method: "create",
    sessionId: globs.userData.session.sessionId, body: {qrcode: st.qrcode,
      type: st.selModuleType,uid:st.uid,hwVers:st.hwVers}})).data
//     let module={error:false}
//     cl(module)
    if (module.error) {
      this.setState({errorMsg: module.error, statusMsg: ""})
    } else {
      // cl(module)
      if(st.gotLocal){
        status=await this.testProdST(module.uid)
      }else{
        status=await this.makeRemoteFW(st.uid)
      }
      if(status=="ok"){
        // cl(st.qrcode)
        if(st.qrcode){
          labCode=this.makeLabelCode(st.qrcode)
          // add ds
          if (st.selModuleType.split("-")[1]) labCode+=`-${st.selModuleType.split("-")[1]}`
          // add address
          labCode = `_${labCode}-${st.selectedModuleAddr}`
        }
        name = `${st.selModuleType}${labCode||""}.bin`
        let fileLoc=(st.gotLocal)?"C:\\ST\\ModuleFirmware.bin is the firmware to install to this module.":`Downloaded as ${name}`
        msg=`Module registered successfully! ${fileLoc}`
      }else{
        msg="Error! Module Firmware was not created."
      }
      // send to local react server with module type and id
      // show success dialog upon return from local api call
    }
    this.setState({statusMsg: msg,status:status, errorMsg: ""})

  }

  setQrCodeListener=()=>{
//     cl("set listener")
    wsTrans("usa", {cmd: "cRest", uri: "/s/qrcodeListeners", method: "create",
      sessionId: globs.userData.session.sessionId,body: {}})
    this.subscribe_qrcodeReceived=globs.events.subscribe("qrcodeReceived",this.gotQrCode)
  }

  testProdST=async(uid)=>{
    // note that for localhost to be reachable, this setting must be changed in Chrome:
    // 1: go to chrome://flags/#block-insecure-private-network-requests
    // 2: set Block insecure private network requests to Disabled
    // from here:
    // https://stackoverflow.com/questions/66534759/cors-error-on-request-to-localhost-dev-server-from-remote-site
    //     cl("testProdST")
    //     doGetPostBasic("http://localhost:3105/usa/serverInfo","GET")
        let st=this.state
        let url="http://localhost:3105/prodst"
        let method="POST"
        let obj={code:uid,type:st.selModuleType}
        let msg=JSON.stringify(obj)
        let type="application/json"
        cl(msg)
        let res=await doGetPostBasic(url,method,msg,type)
        let resObj=await res.json()
        cl(resObj)
        return resObj.result
//         if(resObj.result=="error"){}
//         cl(resObj)
    // this is currently returning {result:"ok"}, or "noFile", or "noMagic"
    }

  loadWsTrans=async(parms)=>{
    return await wsTrans("usa", {cmd: "cRest", uri: parms.uri, 
      method: parms.method||"retrieve", sessionId: globs.userData.session.sessionId, 
      body: parms.body})
  }

  loadCurrentFirmware=async()=>{
//     constant.urls.prod0="https://downloads.link4cloud.com"
//     doGetPost("prod0","moduleFW/currentFW.json","GET")
  // let url="https://downloads.link4cloud.com/moduleFW/currentFW.json"
  // cl(config.server)

  let url=(config.server=="stage")?
    "http://stggrn.link4cloud.com:3115/usa/fw/currentFW.json":
    "https://http0.link4cloud.com/usa/fw/currentFW.json"
  // cl(url)
  let res=await fetch(url,{})
  this.curFW=await res.json()
  // cl("Current FW retrieved:")
  // cl(this.curFW)
  }

  checkLocalServer=async()=>{
    let url="http://localhost:3105/usa/serverInfo"
    let method="GET"
    let res=await doGetPostBasic(url,method)
    let obj=await res.json()
    return obj.result=="ok"
//     cl(obj)
  }

  loadInfo=async()=>{
    // cl("load info")
    let accounts=(await this.loadWsTrans({uri:"/su/suAccounts"})).data
    let sites=(await this.loadWsTrans({uri:"/su/allSites"})).data
    let zones=(await this.loadWsTrans({uri:"/su/allZones"})).data
    let allModules = (await this.loadWsTrans({uri:"/su/allModules"})).data
    // cl(allModules)
    this.loadCurrentFirmware()
    let gotLocal=await this.checkLocalServer()
//     cl(gotLocal)
    this.setState({
      accounts:accounts,
      sites:sites,
      zones:zones,
      allModules:allModules,
      gotLocal:gotLocal,
    })
//     cl("making Modules")
//     await this.makeBatchModules()
//     cl("making Modules done")
  }

  saveModule=async()=>{
    // save name, account, site, zone to this module
    let module = this.state.selModule
    Object.assign(module, {accountId: this.state.selAccount}, 
      {siteId: this.state.selSite}, {zoneId: this.state.selZone}, {name: this.state.selModuleName})
    let updated = await wsTrans("usa", {cmd: "cRest", uri: "/s/moduleIds", method: "update",
    sessionId: globs.userData.session.sessionId, body: module})
    // cl(updated)
    // update once saved
    if (updated.error) {
      this.setState({
        statusMsg: ``,
        errorMsg: `Error: Could not update because module with qr code ${this.state.qrcode} was not found.`,
      })
    } else {
      module = (await wsTrans("usa", {cmd: "cRest", uri: "/s/moduleIds", method: "retrieve",
        sessionId: globs.userData.session.sessionId, body: {qrcode: module.qrcode, moduleId: module.moduleId}})).data
      if (module.error) {
        this.setState({
          statusMsg: ``,
          errorMsg: `Error: Module with qr code ${this.state.qrcode} was not found.`,
        })
      } else {
        cl("fetched updated module")
        module = module[0]
        // cl(module)
        delete module._id
        let vals = {
          selModule: module, 
          statusMsg: `Module with label code ${this.makeLabelCode(module.qrcode)} updated!`,
          errorMsg: ``,
          selAccount: this.state.selAccount, 
          selSite: this.state.selSite, 
          selZone: this.state.selZone,
          selModuleName: module.name || "",
        }
        this.setState(vals)
      } 
    }
    globs.events.publish("saveOK",true)
  }

  showSelectAccount=()=>{
    let accts = this.state.accounts.map((a,i)=>{
      return(
        <option key={i} value={a.accountId}>{a.name}</option>
      )
    })
    accts.unshift(<option key={-1} value={""}></option>)
    return (
      <div className="custom-select">
        <label htmlFor="">Account</label>
        <C18Select00 
          id="account-select"
          onChange={e=>this.onChange("sel",{selAccount: e.currentTarget.value})}
          value={this.state.selAccount}
        >
        {accts}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </div> 
    )
  }

  showSelectSite=()=> {
    let sites = this.state.sites.map((s,i)=>{
      if (s.accountId == this.state.selAccount) {
        return(
          <option key={i} value={s.siteId}>{s.name}</option>
          )
      }
    })
    sites.unshift(<option key={-1} value={""}></option>)
    return(
      <span className="custom-select">
        <label htmlFor="report-site">Site</label>
        <C18Select00 
          id="site-select"
          onChange={(e)=>this.onChange("sel", {selSite: e.currentTarget.value})}
          value={this.state.selSite}
        >
          {sites}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </span>
    )
  }

  showSelectZone=()=>{
    let zones = this.state.zones.map((z,i)=>{
      if (z.siteId == this.state.selSite) {
        return(
          <option key={i} value={z.zoneId}>{z.zoneName}</option>
          )
      }
    })
    zones.unshift(<option key={-1} value={""}></option>)
    return(
      <span className="custom-select">
        <label htmlFor="report-zone">Zone</label>
        <C18Select00 
          id="zone-select"
          onChange={(e)=>this.onChange("sel", {selZone: e.currentTarget.value})}
          value={this.state.selZone}
        >
          {zones}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </span>
    )
  }

  showSelectModuleType=()=>{
    // object types
    let opts = allModuleTypes.map((m)=>{
      return {v: m.id, t: m.name}
    })
    opts.unshift({v: -1, t: "Select a Type"})
    
    return(
      <span className="custom-select">
        <label htmlFor="report-zone">Module Type</label>
        <C18Select00 
          id="report-zone"
          onChange={(e)=>this.onChange("selModuleType", {selModuleType: e.currentTarget.value})}
          value={this.state.selModuleType}
        >
          {opts.map((o,i)=>{
            return(
              <option key={i} value={o.v}>{o.t}</option>
            )
          })}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </span>
    )
  }

  showSelectModuleAddr=()=>{
    let st=this.state
    // cl(st.selModuleType)
    // cl(allModuleTypes)
    let opts=[]
    if(st.selModuleType!=-1 && st.selModuleType!="prl" && st.selModuleType!="prla"){
      let range=this.modAddrRange[st.selModuleType]
      for(let i=range[0];i<=range[1];i++){
        let addr=128+i
        opts.push({v: addr, t: addr})
      }
      opts.push({v: this.varModuleAddr, t: "Randomized MB Address"})
      opts.push({v: this.noModuleAddr, t: "No MB Address"})
    }
    opts.unshift({v: -1, t: "Select a MB Address"})

    return(
      <span className="custom-select">
        <label>Module Address</label>
        <C18Select00
          onChange={(e)=>this.onChange("moduleAddr", {moduleAddr: e.currentTarget.value})}
          value={this.state.moduleAddr}
        >
          {opts.map((o,i)=>{
            return(
              <option key={i} value={o.v}>{o.t}</option>
            )
          })}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </span>
    )
  }

  showSelectHWVers=()=>{
    let opts=[
      {v:1,t:"X1"},
      {v:2,t:"X2"},
      {v:3,t:"X3"},
      {v:4,t:"X4"},
      {v:5,t:"X5"},
      {v:6,t:"X6"},
      {v:7,t:"X7"},
      {v:8,t:"X8"},
      {v:9,t:"X9"},
    ]
    return(
      <span className="custom-select">
        <label>Hardware Version</label>
        <C18Select00
          onChange={(e)=>this.onChange("hwVers", {hwVers: e.currentTarget.value})}
          value={this.state.hwVers}
        >
          {opts.map((o,i)=>{
            return(
              <option key={i} value={o.v}>{o.t}</option>
            )
          })}
        </C18Select00>
        <span className="material-icons down-arrow">
          keyboard_arrow_down
        </span>
      </span>
    )
  }

  showCurModuleType=()=>{
    let st=this.state
    // cl(this.curFW)
    // cl(st)
    if(st.selModuleType!=-1){
      let str=this.curFW[st.selModuleType]
      // cl(str)
      if(st.enabled){
        let parts=str.split("_")
        let da=parts[4]?.split(".")[0]
        let da2
        if (da) {
          let yr=da?.substring(0,4)
          let mo=da?.substring(4,6)
          let dy=da?.substring(6,8)
          da2=`${mo}-${dy}-${yr}`
        }
        return(
          <table style={{width:"initial"}}><tbody>
          <tr><td>Type:</td><td>{`${parts[0]} ${(st.selModuleType=="prla") ? parts[1] : ""}` }</td></tr>
          <tr><td>Version:</td><td>{`${(st.selModuleType=="prla") ? parts[3] : parts[2]}`}</td></tr>
          <tr><td>Commit:</td><td>{`${(st.selModuleType=="prla") ? parts[4] : parts[3]}`}</td></tr>
          <tr><td>Date:</td><td>{da2}</td></tr>
          </tbody></table>
        )
      }else{
        return(
          <div>
            <span style={{backgroundColor:"red",color:"white",fontWeight:1000,
              width:"initial",padding:5
            }}>No Firmware Available!!!</span>
          </div>
        )
      }
    }
  }

  showQrCode=()=>{
    // object types
    let st=this.state
    // cl(this.state)
    var labCode
    if(st.qrcode && st.qrcode != -1){
      let code=intToFiveBit(st.qrcode)
      labCode=`${code.substring(0,3)}-${code.substring(3,6)}`
      // add ds
      if (st.selModuleType && st.selModuleType.split("-")[1]) labCode+=`-${st.selModuleType.split("-")[1]}`
      // add address
      if (st.selectedModuleAddr) labCode += `-${st.selectedModuleAddr}`
    }else{
      labCode=""
    }
    
//         UID: {(st.uid||"").toString(16)}<br/>
//         Label Code:{labCode}<br/>
//         QR Code: <i>{this.state.qrcode}</i>
//         <br/>
//         <br/>
    return(
      <div className="text-input-wrapper">
      <table style={{width:"initial"}}><tbody>
      <tr><td>UID:</td><td>{(st.uid||"No UID").toString(16)}</td></tr>
      <tr>
        <td>Label Code:</td><td>{labCode}</td>
        {labCode !== "" &&
          <td>
          <button type="button" className="material-icons-outlined copy-button" title="Copy"
            onClick={()=>this.onChange("copyLabel", {labCode: labCode})}
          >
            content_copy
          </button>
          </td>
        }
        <td>
          <div>{this.state.copyText}</div>
        </td>

      </tr>
      <tr><td>QR Code:</td><td><i>{this.state.qrcode||"NO QR Code"}</i></td></tr>
      </tbody></table><br/>
      </div>
    )
  }

  showModules=()=>{
    // table controls
    let st = this.state
    let sortMode=st.sortMode
    let modules = this.filterModules()
    cl(modules)
    let icons=[]
    for(let i=0;i<4;i++){
      var icon
      if((Math.abs(sortMode)==(i+1))){
        icon=(sortMode>0)?"keyboard_arrow_down":"keyboard_arrow_up"
      }else{
        icon=""
      }
      icons.push(icon)
    }
    if (!modules || modules.length == 0) {
      return (
        <div><i>No results found...</i></div>
      )
    }
    return(
      <div>
        <div className="table-container">
          <table className="list">
            <tbody>
            <tr>
              <th style={{textAlign: "left", width:"25%"}}>
                <button type="button" aria-label="sort-account" 
                  onClick={()=>this.onChange("sort",{column:1})}>
                  <h2>Name<span className="material-icons-outlined">{icons[0]}</span></h2>
                </button>            
              </th>
              <th style={{textAlign: "left", width:"25%"}}>
                <button type="button" aria-label="sort-plan" 
                  onClick={()=>this.onChange("sort",{column:2})}>
                  <h2>Type<span className="material-icons-outlined">{icons[1]}</span></h2>
                </button>            
              </th>
              <th style={{textAlign: "left", width:"25%"}}>
                <button type="button" aria-label="sort-unlocked" 
                  onClick={()=>this.onChange("sort",{column:3})}>
                  <h2>UID<span className="material-icons-outlined">{icons[2]}</span></h2>
                </button>            
              </th>
              <th style={{textAlign: "left", width:"25%"}}>
                <button type="button" aria-label="sort-date" 
                  onClick={()=>this.onChange("sort",{column:4})}>
                  <h2>Label Code<span className="material-icons-outlined">{icons[3]}</span></h2>
                </button>            
              </th>
            </tr>
          </tbody>
        </table>
        </div>
        <div style={{backgroundColor:this.bgColor2, overflowY:"auto"}}>
        <div className="table-container" style={{overflowY: "scroll", height: "250px"}}>
          <table className="list"><tbody>
            {modules.map((a,i)=>{
              // let val={}
              // val[selId]=a[itemId]
              let bg = (st.selModule?.moduleId == a.moduleId) ? this.selColor1:null
              return(
                <tr key={i} style={{backgroundColor:bg,cursor:"pointer"}} onClick={e=>this.onChange("itemClick",{selModule: a})}>
                  <td style={{textAlign: "left", width:"25%"}}>{a.name || ""}</td>
                  <td style={{textAlign: "left", width:"25%"}}>{a.type || ""}</td>
                  <td style={{textAlign: "left", width:"25%"}}>{(a.uid||"").toString(16)}</td>
                  <td style={{textAlign: "left", width:"25%"}}>{this.makeLabelCode(a.qrcode)}</td>
                </tr>
              )
            })}
          </tbody></table>
        </div>
        </div>
        <br/>
        <div><i>{modules.length} results found...</i></div>
        <br/>
      </div>
    )
  }

  showModuleType=()=>{
    return(
      <div className="text-input-wrapper">
        Module Type: <i>{this.state.selModule.type}</i>
        <br/>
        <br/>
      </div>
    )
  }

  showModuleName=()=>{
    return(
      <div className="text-input-wrapper">
        <label htmlFor="new-user-name">Name</label>
        <input id="new-user-name" type="text" 
          value={this.state.selModuleName} 
          onChange={e=>this.onChange("editName",{selModuleName:e.currentTarget.value})}
          />
        <br/>
      </div>
    )
  }

  showModuleInfo=()=>{
    let accountName = (this.state.accounts.find((a) => this.state.selModule.accountId == a.accountId))?.name
    let siteName = (this.state.sites.find((a) => this.state.selModule.siteId == a.siteId))?.name
    let zoneName = (this.state.zones.find((a) => this.state.selModule.zoneId == a.zoneId))?.zoneName
    return(
      <div className="text-input-wrapper">
        <div>
          Account: <i>{accountName || ""}</i>
        </div>
        <br/>
        <div>
          Site: <i>{siteName || ""}</i>
        </div>
        <br/>
        <div>
          Zone: <i>{zoneName || ""}</i>
        </div>
        <br/>
      </div>
    )
  }

  showSelectedModule=()=>{
    if (this.state.selModule) {
      return (
        <div>
          {this.showQrCode()}
          {this.showModuleType()}
          {this.showModuleInfo()}
          {this.showModuleName()}
        </div>
      )
    }
  }

  showAddModule=()=>{
    let st=this.state
//     cl(st)
//     let disable=!(st.selAccount&&st.selSite&&st.selZone)
//     disabled={disable}
    return(
      <div>
      <label htmlFor="moduleCode">Module Code</label>
      Enter code (abc-123), numeric QR Code, or UID in Hex<br/>
      <input type="text" style={{display:"inline-block"}}
      value={st.addModuleCode}
      onChange={e=>this.onChange("addModuleCode",{addModuleCode:e.currentTarget.value})}
      />&nbsp;
      <C18Button00 type="button" className="outlined"
        onClick={e=>this.onChange("addModuleFromCode")}>Add Module Code</C18Button00>
      </div>
    )
  }

  showModuleSearch=()=>{
    let st=this.state

    return(
      <div>
      <label htmlFor="moduleCode">Module Search</label>
      <input type="text" style={{display:"inline-block"}}
      value={st.searchModuleTxt}
      onChange={e=>this.onChange("searchModuleTxt",{searchModuleTxt:e.currentTarget.value})}
      />&nbsp;
      </div>
    )
  }

  showErrorMsg=()=>{
    return (
      <div style={{color: "red"}}>
        {this.state.errorMsg != "" && this.state.errorMsg}
      </div>
    )
  }

  showStatusMsg=()=>{
    let st=this.state
    let bgColor=(st.status=="ok")?"#88CCCC":"#FF8888"
    return (
      <div style={{backgroundColor:bgColor,color:"white"}}>
        {this.state.statusMsg != "" && this.state.statusMsg}
      </div>
    )
  }
  showProduction=()=>{
    let st=this.state
    // cl(st.selModuleType)
    // cl([!st.enabled, !this.state.qrcode,(this.state.selModuleType == -1),(!this.state.selectedModuleAddr), (this.state.selModuleType!="prl" || this.state.selModuleType!="prla")])
    return (
      <div>
        <br/>
        <br/>
        <br/>
        {this.showSelectModuleType()}<br/>
        {this.showSelectModuleAddr()}<br/>
        {this.showSelectHWVers()}<br/>
        {this.showCurModuleType()}<br/>
        Select the type and the address, Create Code, then Create Binary to save the info to the DB,<br/>
        and to create the firmware with this ID in it.<br/>
        Then use the ST Link Programmer to flash the firmware to the module.<br/><br/>
        <C18Button00 type="button" className="outlined" disabled={
          (!st.enabled)||(st.selModuleType == -1)||(st.selModule)||(st.moduleAddr==-1)}
          onClick={e=>this.onChange("createCode")}>Create Code</C18Button00>
        <div className="clearfloat"/><br/>
        {this.showQrCode()}
        <C18Button00 type="button" className="outlined" onClick={e=>this.createBinary()}
        disabled={
          ((!st.enabled)
          || !this.state.qrcode 
          || (this.state.selModuleType == -1) 
          || (!this.state.selectedModuleAddr))
          && (this.state.selModuleType!="prl" && this.state.selModuleType!="prla")
        }>
          Create Binary
        </C18Button00>
        <br/>
        <br/>
        {this.showErrorMsg()}
        {this.showStatusMsg()}
      </div>
    )
  }



  showInstallation=()=>{
    // TODO have select that will reveal module type and name of selected
    // TODO editing name or scanning new module  will make save light up (only if asz are selected)
    return (
      <div>
        <br/>
        <br/>
        <br/>
        {this.showErrorMsg()}
        {this.showStatusMsg()}
        {this.showModuleSearch()}
        <br/>
        {this.showSelectAccount()}
        <br/>
        {this.showSelectSite()}
        <br/>
        {this.showSelectZone()}
        <br/>
        {this.showModules()}
        <br/>
        {this.showSelectedModule()}
        <br/>
        {/* {this.showAddModule()} */}
      </div>
    )
  }

  showTabs=()=>{
    switch(this.state.tabs){
      case "Production":
        return(this.showProduction())
      case "Installation":
          return(this.showInstallation())
    }
  }

  render(){
    let st=this.state
//     cl(st)
    let tabs = [{v: "Production", t: "Production"}, {v: "Installation", t: "Installation"}]
    return(
      <div>
        <div className="reports-and-graphs">
          <C18SubMenuHeader00 parms={{
            items: tabs,
            pageType: this.state.tabs,
            onChange: o=>this.onChange("switchTab",o),
          }}/>
          {this.showTabs()}
        </div>
      </div>
    )
    } 
}

export default C18QrCodeReceive00;
