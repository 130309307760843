/* eslint-disable default-case */
import React from 'react';
import C18Anchor00 from './C18Anchor00'
import {loadSitesInfo,loadZonesInfo,loadAlarmsInfo,loadAddedSensorsInfo,getSiteInfo,getAlarmInfo,
  getZoneAlarmInfo,getZoneIndex,loadMBInfo,
  getSiteName,getZoneName,setSiteZoneTypes,loadSiteData,privs} from './C18utils'
  import {wsTrans,sensorIds,alarmToSensor,makeSiteSensorNames,siteSensorNames,getParamId,getTzo,getTZIndicator,getDst} from '../utils/utils';
import {cl,globs,constant,dateToDisplayDate} from '../../components/utils/utils';

class C18AlarmLog00 extends React.Component{
  constructor(props) {
    super(props);
    this.loadInfo()
    this.setBreadCrumbs()
    this.state={
      loaded:false,
    }
    if(this.props.notify){this.props.notify({id: "newSensor", func: this.onNewSensor})}
}
    
  componentWillUnmount=()=>{
    if(this.props.notify){this.props.notify({id: "newSensor", func: this.onNewSensor, unMount: true})}
    
  }
  
  onNewSensor=(sensor)=>{
//     cl(sensor)
//     cl(this.props.parms.sensor)
    let alarms=this.getAlarmList()
    this.setState({alarms:alarms})
//     this.handleNewSensor(sensor.data)
  }
  
  setBreadCrumbs=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
    let siteName=getSiteName(this.props.parms.site)
    let zoneName=getZoneName(this.props.parms.zone)
    let scope=this.getScope()
//     cl(scope)
    if(this.props.parms){
      let bc=[
        {t:"Sites", url:"/usa/c18/sites"},
      ]
      if((scope=="site")||(scope=="zone")){
        bc.push(
          {t:siteName, url:`/usa/c18/sites/${this.props.parms.site}`}
        )
      }
      if(scope=="zone"){
        bc.push(
          {t:zoneName, url:`/usa/c18/sites/${this.props.parms.site}/zones/${this.props.parms.zone}`},
        )
      }
      bc.push(
        {t:"Alarm Log", url:`/usa/c18/alarmLog`},
      )
//       cl(bc)
      this.props.parms.onChange(
        {
          cmd: "breadcrumbs",
          data:
            {breadcrumbs: bc},
        },
      )
    }
  }
  
//   componentWillUnmount=()=>{
//     this.subscribe_savePageEvent.remove()
//   }

  getAlarmName=(id)=>{
    
  }
  
  getZoneInfoFromSI=(siteId,index)=>{
    let gzi=globs.zonesInfo.info
    for(let i=0;i<gzi.length;i++){
      let z=gzi[i]
//       cl(z)
      if((z.siteId==siteId)&&(z.siteZoneIndex==index)){return z}
    }
  }
  
  getScope=()=>{
    let p=this.props.parms
    if(p.sensor){return "sensor"}
    if(p.zone){return "zone"}
    if(p.site){return "site"}
    return "account"
  }
  
  makeSensorAlarmCurrentLimit=(zi,type)=>{// duplicated in C18SidebarAlarms00
    let len=type.length
//     cl(zi)
    if(type.substring(len-2,len)=="Sa"){
//       cl(type)
      let sensorType=type.substring(0,5)
      let hilo=type.substring(5,7)
//       cl(sensorType)
      
//       cl(zi.current.sensorInfo)
      let ai=zi.current.sensorInfo[sensorType]
      if(ai){
        let current=ai.val+ai.un
        let limit=((hilo=="Hi")?ai.ah:ai.al)+ai.un
        return [current,limit]
      }else{return [0,0]}
//       cl(ai)
    }
  }
  
  getLimitCurrent=(zi,al)=>{
//     cl(zi,al)
    let limit="--"//"701 W/m2"
    let current="--"//"1002 W/m2"
//     cl(zi)
//     cl(al)
    if(zi.current){
      var res
      if(res=this.makeSensorAlarmCurrentLimit(zi,al)){
        [current,limit]=res
      }else{
        switch(al){
          case "ITHi":
            current=zi.current.inTemp
            limit=zi.current.inTempHAL+zi.current.inTempHSP
            break
          case "ITLo":
            break
          default:
            break
        }
      }
    }
//     cl(limit,current)
    return [limit,current]
  }
  
  getAlarmLevel=()=>{
    
    // cl(this.scope)
    return 
  }
  
  getAlarmSensor=(alarmId)=>{
//     let len=alarmId.length
//     let type=alarmId.substring(len-2,len)
    let ty=alarmId.slice(-2)
    if(ty=="Sa"){
      return alarmId.substring(0,5)
    }
  }
  
  getAlarmList=()=>{
    let pa=this.props.parms
    let alarms=[]
    globs.alarmsInfo.info.forEach(a=>{
//       cl(a)
      let si=getSiteInfo(a.s)
      let zi=this.getZoneInfoFromSI(a.s,a.z)// Site-Index
//       cl([si,zi])
      if(si&&zi&&!zi.virtual){
        let ai=getAlarmInfo(a.a,a.s,a.z)||{}
        
//         if(a.a=="e0as0HiSa"){cl(a,ai)}
//         cl(ai)
//         cl(a)
//         cl(ai)
        if(true || ai.enable!=0){
          let level=ai.level
          let aId=a.a
          let name=ai.name
          let site=si.name
          let siteId=si.siteId
          let zone=zi.zoneName||"Unknown"
          let zoneId=zi.zoneId
          let time=a.b
          var limit,current
          let tzo = getTzo(zi)
          let dst = getDst(zi)
          if(this.scope=="account"){
            limit="--"//"701 W/m2"
            current="--"//"1002 W/m2"
          }else{
            [limit,current]=this.getLimitCurrent(zi,a.a)
          }
          let getIt=false
          let alZone=(globs?.zonesInfo?.sz2z[a.s][a.z]||{})?.zoneId
          var pr
          switch(this.scope){
            case "account":
              pr=privs("zone",alZone,constant.AREA_PRIVS_READ)
              getIt=pr
              break
            case "site":
              pr=privs("zone",alZone,constant.AREA_PRIVS_READ)
              getIt=pr&&(si.siteId==pa.site)
              break
            case "zone":
              pr=privs("zone",alZone,constant.AREA_PRIVS_READ)
              getIt=pr&zi.zoneId==pa.zone
              break
            case "sensor":
              pr=privs("zone",globs?.zonesInfo?.sz2z[a.s][a.z]?.zoneId,constant.AREA_PRIVS_READ)
              getIt=(pr&zi.zoneId==pa.zone)&&(pa.sensor==this.getAlarmSensor(a.a))
              break
          }
          if(getIt){
            alarms.push( {level:level,aId:aId,name:name,site:site,siteId:siteId,zone:zone,zoneId:zoneId, time:time, limit:limit, current:current, tzo: tzo, dst: dst} )
          }
        }
      }
    })
    alarms.sort((a,b)=>{
      if(a.time<b.time){return 1}
      if(a.time>b.time){return -1}
      return 0
    })
//     cl(pa.sensor)
//     cl(alarms)
    return alarms
    
  }
  
  loadInfo=async()=>{
    await loadSitesInfo()
    await loadZonesInfo()
    await loadAlarmsInfo()
    await loadMBInfo()
//     cl(globs)
//     cl(globs.alarmsInfo.info)
    await loadAddedSensorsInfo()
//     cl("load added sensors")
//     cl(globs.addedSensorsInfo.info)
//     cl(globs.alarmsInfo.info)
    let pa=this.props.parms
    if(pa.site){
      await loadSiteData(pa.site)
      await makeSiteSensorNames(pa.site)
//       cl("loading site")
    }
    setSiteZoneTypes(pa.site)
    this.scope=this.getScope()
    if(this.scope!="account"){await getZoneAlarmInfo(globs.userData.session.siteId)}
    let alarms=this.getAlarmList()
    // get timezone info
    if(["zone", "sensor"].includes(this.scope)) {
      let zInd=getZoneIndex(this.props.parms.zone)
    }
    this.setState({loaded:true, alarms:alarms,/*siteSel:siteSel,zoneSel:zoneSel*/})
  }
  
  showAlarmLines=(showLimit)=>{
//     let alarmToSensor={ITHi:"inT",ITHi:"inT",ITSn:"inT",}
//     cl(globs.zonesInfo.info)
//     cl(this.state.alarms)
    return this.state.alarms.map((a,i)=>{
//       cl(a.aId)
//       cl(a.name)
      if(a.level<6){
        // add tzo if present to a.time
        let da=new Date(a.tzo ? ((a.time + (a.tzo * 60) + (a.dst * 60 * 60))*1000): a.time * 1000)
        // cl(da)
        // cl(a.time*1000)
        // cl(this.state.tzo)
        // only get utc disp time if tzo is present
        let dispDate=(a.tzo) ? dateToDisplayDate(da,"mm/dd/yyyy h:mm ap", 0) : dateToDisplayDate(da,"mm/dd/yyyy h:mm ap")
//         let page=(alarmToSensor(a.aId))?`/sensor/${alarmToSensor[a.aId]}`:""
        let url=`/usa/c18/sites/${a.siteId}/zones/${a.zoneId}/SPalarms`//${page}
        return(
          <tr key={i} >
            <td>{false&&<input type="checkbox"/>}</td>
            <td>
              <span className={`small-alarm-icon${(a.level<=3)?" urgent-alarm":""}`}>
                <span className="material-icons-outlined">notifications_active</span> <span className="alarm-level">{a.level}</span>
              </span>
            </td>
          <td><C18Anchor00 to={url}>{a.name}</C18Anchor00>
          </td>
          <td>{a.site}</td><td>{a.zone}</td><td>{dispDate} {a.tzo ? getTZIndicator(a.tzo): ""}</td>
          {showLimit&&[<td key="0">{a.limit}</td>,<td key="1">{a.current}</td>]}
          
          </tr>
        )
      }
    })
  }
  
  makePageName=()=>{
    let scope=this.getScope()
    var zInd,name
    let p=this.props.parms
    switch(scope){
      case "account":
        return "Account Alarm Notifications"
      case "site":
        let si=getSiteInfo(this.props.parms.site)
//         cl(si)
        return `${si.name} Alarm Notifications`
      case "zone":
        zInd=getZoneIndex(this.props.parms.zone)
        name=globs.zonesInfo.info[zInd].zoneName
        return `${name} Alarm Notifications`
      case "sensor":
        zInd=getZoneIndex(this.props.parms.zone)
//         name=globs.zonesInfo.info[zInd].zoneName
//         cl(sensorIds)
//         cl(p)
//         cl(siteSensorNames)
        let se=p.sensor
        if(["ec","ph"].includes(se.substring(2,4))){se=se.substring(0,4)}
        // cl(siteSensorNames)
        // cl(p.zone)
        // cl(se)
        name=`${globs.zonesInfo.info[zInd].zoneName} ${((siteSensorNames[p.zone]||{})[se]||{}).name}`
        return `${name} Alarm Notifications`
    }
    return scope
  }
  
  sendTestAlarm=()=>{
    cl("sending test msg")
    let buttons = []
    buttons[0] = {text: "Log in GJ", cmd: "gj", data: {method: "create", body: {
      msg: "This is an example of a GJ created from a push notification.\n Good for logging important alarms.",
      tags: ["sensors", "other"]
    }}
    }
    buttons[1] = {text: "View", cmd: "url", data: {url: "http://beta.c2.link4cloud.com/usa/c18/alarmLog"}}
    buttons[2] = {text: "Dismiss", cmd: "", data: {}}
    let fakeAlarm = {
      // Name: "Inside Temperature Low Alarm",
      Site: "Anaheim, CA",
      Zone: "Cherry Tomatoes",
      Time: dateToDisplayDate(new Date(),"mm/dd/yyyy h:mm ap"),
      Limit: "95.0 F",
      Current: "98.8 F",
      Level: 1,
      buttons: buttons
    }
    let name = "Inside Temperature Low Alarm"
    wsTrans("usa", {cmd: "cRest", uri: "/s/pushNote", method: "update", 
      sessionId: globs.userData.session.sessionId,
      body: {
        userIds: [globs.userData.session.userId],
        title: `Test Alarm Notification`,
        body: fakeAlarm,
        topic: "alarm",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJiMe2sF84-H1gwFr7qbUNcyBE2i7jQrPg4Q&usqp=CAU",
    }})

    // wsTrans("usa", {cmd: "cRest", uri: "/s/pushNote", method: "update", 
    //   sessionId: globs.userData.session.sessionId,
    //   body: {
    //     userIds: [globs.userData.session.userId],
    //     title: name,
    //     body: JSON.stringify(fakeAlarm),
    //     topic: "alarm",
    //     url: "http://stage.c2.link4cloud.com:3114/usa/c18/alarmLog",
    //     cmd: "",
    //   }})
  }

  render(){
    if(this.state.loaded){
//       cl("loaded")
      let showLimit=(this.scope!="account")
      let name=this.makePageName()
      let p=this.props.parms
//       cl(p.sensor)
//       cl(showLimit)
      return(
        <div>
        {(p.mode=="fullPage")?<h1>{name}</h1>:<h2>{name}</h2>}
              <div className="table-container">
                <table className="active-alarms-table"><tbody>
                  {false&&
                    <tr className="bottom-padding">
                      <td><input type="checkbox" /></td>
                      <td colSpan="8"><button type="button" className="outlined">Snooze Selected Alarms</button></td>
                    </tr>
                  }
                  <tr className="spacer">
                    <td colSpan="9"></td>
                  </tr>
                  <tr>
                    <th></th><th>Level</th><th>Alarm</th><th>Site</th><th>Zone</th><th>Date</th>
                    {showLimit&&[<th key="0">Limit</th>,<th key="1">Current</th>]}
                  </tr>
                  {this.showAlarmLines(showLimit)}
                </tbody></table>
              </div>
              <br/>
              {false && (p.mode=="fullPage") &&
                <div className="table-container">
                  <button className="outlined" type="button" onClick={()=>this.sendTestAlarm()}>Test Alarm Notification</button>
                </div>
              }  
              {false&&
                <div className="report-paging paging">
                  <button type="button" className="back-to-beginning" aria-label="first page">&#171;</button>
                  <button type="button" className="back" aria-label="previous page">&#8249;</button>
                  <span className="page-range">1-99 of 200</span> 
                  <button type="button" className="forward" aria-label="next page">&#8250;</button>
                  <button type="button" className="forward-to-end" aria-label="last page">&#187;</button>
                </div>
              }
              <div className="clearfloat"></div>
        </div>
      )
    }else{
      return <div id="content-area">loading. . .</div>
    }
  }
}

export default C18AlarmLog00;
