import React from 'react';
import { cl, globs, constant } from '../../components/utils/utils';
import { wsTrans } from '../utils/utils'
import { getSiteInfo } from './C18utils'
import DayClearIcon from '../../visualization/icons/sun-icon.svg';
import NightClearIcon from '../../visualization/icons/moon-icon.svg';
import DayCloudIcon from '../../visualization/icons/day-cloudy-icon.svg';
import NightCloudIcon from '../../visualization/icons/night-cloudy-icon.svg';
import OvercastIcon from '../../visualization/icons/cloud-icon.svg';
import DaySnowIcon from '../../visualization/icons/day-cloud-snow-icon.svg';
import NightSnowIcon from '../../visualization/icons/night-cloud-snow-icon.svg';
import DayRainIcon from '../../visualization/icons/day-cloud-rain-icon.svg';
import NightRainIcon from '../../visualization/icons/night-cloud-rain-icon.svg';
import DayThunderstormIcon from '../../visualization/icons/day-cloud-rain-lightning-icon.svg';
import NightThunderstormIcon from '../../visualization/icons/night-cloud-rain-lightning-icon.svg';
import FunnelIcon from '../../visualization/icons/hurricane-icon.svg';

class C18WeatherInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            weather: true,
        }
        this.loadInfo()
    }

    loadInfo = async () => {
        if (this.state.weather) {
            let address
            let body = { endpoint: "forecast" }

            if (this.props.parms.siteId) {
                let site = getSiteInfo(this.props.parms.siteId)
                if (site.thoroughfare && site.locality && site.administrativeArea) {
                    Object.assign(body, { siteId: this.props.parms.siteId })
                    if (site.coordinates) {
                        Object.assign(body, { coordinates: site.coordinates })
                    } else {
                        address = `${site.thoroughfare} ${site.locality} ${site.administrativeArea}`
                        address = address.replaceAll(" ", "%20")
                        Object.assign(body, { address: address })
                    }
                } else {
                    Object.assign(body, { accountId: globs.userData.session.accountId })
                    if (globs.accountInfo.info.thoroughfare && globs.accountInfo.info.locality && globs.accountInfo.info.administrativeArea) {
                        if (globs.accountInfo.info.coordinates) {
                            Object.assign(body, { coordinates: globs.accountInfo.info.coordinates })
                        } else {
                            address = `${globs.accountInfo.info.thoroughfare} ${globs.accountInfo.info.locality} ${globs.accountInfo.info.administrativeArea}`
                            address = address.replaceAll(" ", "%20")
                            Object.assign(body, { address: address })
                        }
                    }
                }
            }
            else {

                Object.assign(body, { accountId: globs.userData.session.accountId })
                if (globs.accountInfo.info.thoroughfare && globs.accountInfo.info.locality && globs.accountInfo.info.administrativeArea) {
                    if (globs.accountInfo.info.coordinates) {
                        Object.assign(body, { coordinates: globs.accountInfo.info.coordinates })
                    } else {
                        address = `${globs.accountInfo.info.thoroughfare} ${globs.accountInfo.info.locality} ${globs.accountInfo.info.administrativeArea}`
                        address = address.replaceAll(" ", "%20")
                        cl(address)
                        Object.assign(body, { address: address })
                    }
                }
            }
            if (body.coordinates || body.address) {
                let forecast = await wsTrans("usa", {
                    cmd: "cRest", uri: "/s/weather", method: "retrieve",
                    sessionId: globs.userData.session.sessionId, body: body
                })
                this.setState({ forecast: forecast.data?.properties })
            }
        }
    }

    findMatchingWord(sentence) {

        const wordList = [
          'sunny',
          'fair',
          'clear',
          'windy',
          'breezy',
          'clouds',
          'cloudy',
          'overcast',
          'snow',
          'ice',
          'rain',
          'drizzle',
          'showers',
          'thunderstorm',
          'pellets',
          'funnel',
          'tornado',
          'hurricane',
          'storm',];
    
        const lowercaseSentence = sentence.toLowerCase();
        for (let i = 0; i < wordList.length; i++) {
          if (lowercaseSentence.includes(wordList[i])) {
            return wordList[i];
          }
        }
        return wordList[0];
      }

    makeWeatherIconsEnum = (forecastedSentence, isDaytime) => {

        const sentence = forecastedSentence;
        const matchingWord = this.findMatchingWord(sentence);
        let forecastedIcon = [];
    
        if (isDaytime) {
          let icons = {
            "sunny": DayClearIcon,
            'fair': DayClearIcon,
            'clear': DayClearIcon,
            'windy': DayClearIcon,
            'breezy': DayClearIcon,
            'clouds': DayCloudIcon,
            'cloudy': DayCloudIcon,
            'overcast': OvercastIcon,
            'snow': DaySnowIcon,
            'ice': DaySnowIcon,
            'rain': DayRainIcon,
            'drizzle': DayRainIcon,
            'showers': DayRainIcon,
            'thunderstorm': DayThunderstormIcon,
            'pellets': DayThunderstormIcon,
            'funnel': FunnelIcon,
            'tornado': FunnelIcon,
            'hurricane': FunnelIcon,
            'storm': FunnelIcon,
          }
          forecastedIcon = icons[matchingWord]
        }
    
        else {
          let icons = {
            "sunny": NightClearIcon,
            'fair': NightClearIcon,
            'clear': NightClearIcon,
            'windy': NightClearIcon,
            'breezy': NightClearIcon,
            'clouds': NightCloudIcon,
            'cloudy': NightCloudIcon,
            'overcast': OvercastIcon,
            'snow': NightSnowIcon,
            'ice': NightSnowIcon,
            'rain': NightRainIcon,
            'drizzle': NightRainIcon,
            'showers': NightRainIcon,
            'thunderstorm': NightThunderstormIcon,
            'pellets': NightThunderstormIcon,
            'funnel': FunnelIcon,
            'tornado': FunnelIcon,
            'hurricane': FunnelIcon,
            'storm': FunnelIcon,
          }
    
          forecastedIcon = icons[matchingWord]
        }
    
        return forecastedIcon;
      }

    showForecast = () => {
        // change display based on what's available?
        if (this.state.forecast) {
            let units = {
                "degF": String.fromCharCode(176) + "F",
                "degC": String.fromCharCode(176) + "C",
                "percent": "%",
            }
            // let curr = this.state.forecast.periods[0]
            // let name = curr.name
            // let temp = `${curr.temperature}\u00B0${curr.temperatureUnit}`
            // let short_forecast = curr.shortForecast
            // let detailed_forecast = curr.detailedForecast
            // let wind_dir = curr.windDirection
            // let wind_spd = curr.windSpeed
            // let pop = `${curr.probabilityOfPrecipitation.value} ${units[curr.probabilityOfPrecipitation.unitCode.split(":")[1]]}`
            // let dewpoint = `${curr.dewpoint.value} ${units[curr.dewpoint.unitCode.split(":")[1]]}`
            // let rh = `${curr.relativeHumidity.value} ${units[curr.relativeHumidity.unitCode.split(":")[1]]}`
            // skip every other period
            return (
                <span>
                    {this.state.forecast.periods?.map((t, i) => {
                        if (t.isDaytime) {
                            // get appropriate icon
                            let icon = this.makeWeatherIconsEnum(t.shortForecast||"", t.isDaytime)
                            let pop = `${t.probabilityOfPrecipitation?.value||0}${units[t.probabilityOfPrecipitation?.unitCode.split(":")[1]]}`
                            return (
                                <span key={i}>
                                <span style={{ fontWeight: "600" }}>{`${t.name}`} </span>
                                <img src={icon} width="20px" />
                                <br></br>
                                <span>Temperature: {`${t.temperature}\u00B0${t.temperatureUnit}`}</span>
                                <br></br>
                                <span>Wind: {t.windDirection} | {t.windSpeed}</span>
                                <br></br>
                                {t.relativeHumidity &&
                                    <span>Relative Humidity: {`${t.relativeHumidity?.value||""}${units[t.relativeHumidity?.unitCode.split(":")[1]]||""}`}</span>
                                }
                                {t.relativeHumidity &&
                                   <br></br>
                                }
                                <span>Rain: {pop}</span>
                                <br></br>
                                <br></br>
                            </span>
                        )
                        }
                    })}
                </span>
            )
        } else {
            return <div style={{textAlign:"center"}}><i>No weather information available</i></div>
        }
    }


    render() {
        return (
            <div>
                {this.showForecast()}
            </div>
        )
    }
}

export default C18WeatherInfo;
